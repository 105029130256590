<template>
    <div class="emb-card pa-4 admin-profile-wrap">
      <div class="mb-8">
        <h5>Link Generator</h5>

      <div>
    <v-container>
    <!-- UTM Parameters Input Fields -->
    <v-row>
      <v-col cols="12" md="4">
        <v-select v-model="utmParams.source" :items="sourceOptions" label="Source" outlined></v-select>
      </v-col>
      <v-col cols="12" md="4">
        <v-select v-model="utmParams.medium[utmParams.source]" :items="mediumOptions[utmParams.source]" label="Medium" outlined></v-select>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="utmParams.campaign" label="Campaign (name_YYYYMMDD)" outlined></v-text-field>
      </v-col>
    </v-row>

    <v-row align="center" class="mt-6">
      <v-col cols="9">
        <v-text-field v-model="generatedShortUrl" label="Generated URL" outlined readonly></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-btn color="primary" @click="copyToClipboardShortUrl" :disabled="utmParams.campaign.trim()==''">
          <v-icon>mdi-content-copy</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-btn color="primary" @click="generateShortUrl" :disabled="urlGenerationRunning">
      <div v-if="!urlGenerationRunning">Short URL</div>
      <div v-else>
        <v-progress-circular
          indeterminate
          color="white"
        ></v-progress-circular>
      </div>
    </v-btn>
  </v-container>

      </div>
  

    </div>
    </div>
  </template>

<script>
import { getFunctions, httpsCallable } from "firebase/functions";
export default {
  data() {
    return {
        utmParams: {
            source: 'newsletter',
            medium: {
                newsletter: 'email',
                social: 'facebook',
                referral: 'affiliate'
            },
            campaign: ''
        },
        generatedUrl: '',
        generatedShortUrl: '',
        mediumOptions: {
            newsletter:['email'],
            social:['facebook', 'twitter','instagram', 'tiktok'],
            referral:['affiliate', 'partner']
        },
        sourceOptions: ['newsletter', 'social', 'referral'],
        urlGenerationRunning: false
    };
  },
  methods: {
    copyToClipboardShortUrl() {
        const textarea = document.createElement('textarea');
        textarea.value = this.generatedShortUrl;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        this.$snotify.success("Short URL copied!", {
            closeOnClick: false,
            pauseOnHover: false,
            timeout: 1000,
        });
    },
   
    generateShortUrl() {
      this.urlGenerationRunning = true;
      const createShortUrlFunc = httpsCallable(
          getFunctions(),
          "createShortLink"
        );
        
        createShortUrlFunc({
          utm_source: this.utmParams.source,
          utm_medium: this.utmParams.medium[this.utmParams.source],
          utm_campaign: this.utmParams.campaign,
        })
          .then((result) => {
            if (result.data.code == "OK") {
              // Get the current base url
              const baseUrl = window.location.origin;
              this.generatedShortUrl = baseUrl + "?s=" + result.data.shortUrlId;
            } else {
              this.$snotify.error(result.data.message, {
                closeOnClick: false,
                pauseOnHover: false,
                timeout: 3000,
              });
            }
            this.urlGenerationRunning = false;
          })
          .catch((error) => {
            console.log(error);
            this.urlGenerationRunning = false;
          });


    }
  },
  mounted() {
   
  },
};
</script>
